<template>
  <div class="pbf">
     <BrandTabReviews
        v-if="type == 'brands'"
        :inurl="`/reviews`"
        :brand-id="props.id" 
      />
      <BrandTabReviews
          v-else
          :inurl="`/reviews`"
          :product-id="props.id" 
        />
  </div>
</template>

<script setup lang="ts">

interface Props {
  id: number
  type: string
}

const props = defineProps<Props>()

</script>